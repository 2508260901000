@import "../../variables";

.header {
  &-container {
    height: 60px;
    box-shadow: 1px 1px 1px #c4c4c4;
    background: #e7d5b4;
    width: 100%;
  }

  max-width: 1320px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  margin: auto;

  &-links {
    display: flex;
    align-items: center;
    height: 100%;
    a{
      text-decoration: none;
      color: black;
      margin-right: 60px;
      display: flex;
      align-items: center;
      font-family: 'Lovelo', cursive;
      height: 100%;
      border-bottom: 4px solid transparent;
      svg{
        margin-right: 5px;
      }
      svg:hover{
        fill: black;
      }
      path:hover{
        fill: black;
      }

    }
    &-active{
      border-bottom: 4px solid black!important;
    }
    a:hover{
      font-weight: 500;
      border-bottom: 4px solid black;
    }

  }

  &-logo {
    &-container {
      display: flex;
      align-items: center;

      h1 {
        font-size: 25px;
        padding-top: 0.3rem;
        font-family: Lovelo, cursive;
      }
    }

    width: 18px;
    height: 25px;
    overflow: hidden;
    border-radius: $borderRadius;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-btn {
    display: flex;
    &-mobile {
      display: none;
    }
    &-register {
      background: $logo;
      margin-right: 10px;
      border-radius: $borderRadius;
      border: none;
      font-size: $fontSizeSmall;
      color: #8f6209;
      font-weight: 600;
      padding: 0 30px;
    }

    &-connect {
      background: white;
      margin-right: 10px;
      font-size: $fontSizeSmall;
      border-radius: $borderRadius;
      border: none;
      padding: 0 30px;
      color: #898989;
      font-weight: 600;
    }
  }
}

@media (max-width: 992px) {
  .header {
    &-btn {
      display: none;

    }

    &-links{
      display: none;
    }
    &-btn-mobile {
      display: flex;
      align-items: center;

      button{
        background: none;
        border: none;
      }
    }
  }
}