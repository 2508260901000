@mixin map-scale() {
  transform: scale(0.75);
  top: -90px;
  @media (max-width: 1700px) {
    transform: scale(0.68);
    top: -90px;

  }

}

@mixin card() {
  background-color: white;
  overflow: hidden;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
}