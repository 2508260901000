@import "../../variables";
@import "../../mixin";

.map {
  &-container {
    height: 100vh;
    display: grid;
    grid-template-columns: 3fr 4fr;
    margin: auto;
    max-width: 1320px;
    position: relative;
    padding: 40px 20px;

    &-left {
      position: relative;
    }

    &-mobile {
      display: none;
    }
  }


  &-info {
    @include card();
    background: $underColor;
    padding-bottom: $spaceMedium;
    margin-top: 15px;

    h3 {
      font-size: 15px;
      margin: 5px 0 $spaceMain 0;
    }

    &-top {
      display: flex;
      border-bottom: 1px solid #222d412b;
      background: white;
      height: 60px;

      &-title{
        &-empty{
          padding: 14px 21px;
        }
      }

      p {
        margin-top: 1px;
        margin-bottom: 0;
        font-size: $fontSizeNormal;
        font-weight: 600;
      }

      h2 {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 500;
      }

      &-img {
        width: 90px;
        min-width: 90px;
        margin-right: $spaceHalf;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-text {
      font-size: $fontSizeNormal;
      padding: 22px $spaceHalf 0 $spaceHalf;
      background: $underColor;
      height: 190px;
      line-height: 22px;

      &-empty{
        height: auto;

      }
    }
    &-discover{
      h1{
        margin: 0;
        font-size: $fontSizeBig;
        margin-bottom: 20px;
      }
      p{
        margin: 0;
        font-size: $fontSizeLarge;
        margin-bottom: 20px;

      }
      span{
        margin: 0;
        font-size: $fontSizeNormal;
        margin-bottom: 20px;
      }
    }

    &-bottom {
      width: 100%;
      background: $underColor;
      padding: 22px $spaceHalf 0 $spaceHalf;
      &-title{
        margin-top: 25px!important;
      }
      h3{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
          font-size: $fontSizeSmall;
          font-weight: 400;
        }
      }

      &-eco {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 15px;
        span{
          font-size: 13px;
        }

        h4 {
          font-size: $fontSizeSmall;
          margin: 0;

          svg{
            margin-right: 7px;
          }
        }

        button {
          background: none;
          border: none;
          height: 10px;
        }
      }

      &-rank {
        width: 100%;
        margin-top: $spaceMain;


        display: grid;
        grid-template-columns: 42px auto;

        &-nb {
          color: white;
          padding: $spaceMain $spaceSmall;
          text-align: center;
          display: flex;
          align-items: center;
          border-bottom-left-radius: $borderRadius;
          border-top-left-radius: $borderRadius;
          background: $logoLight;
          font-size: $fontSizeNormal;
          justify-content: center;

          span {
            display: block;
          }

          &-1 {
            background: #ffb217;
          }

          &-2 {
            background: #797979;
          }

          &-3 {
            background: brown;
          }
        }

        &-text {
          padding: $spaceMain;
          border-bottom-right-radius: $borderRadius;
          border-top-right-radius: $borderRadius;
          background: white;
          font-size: $fontSizeNormal;
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &-business {
        background: white;
        height: 10rem;
        width: 100%;
        border-radius: $borderRadius;
      }
    }
  }
}


@media (max-width: 992px) {
  .map {
    &-mobile {

      &-desc{
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
    &-container {
      height: auto;
      margin-top: 10px;
      display: block;
      padding: 20px;
      &-left {
        display: none;
      }

      &-mobile {
        display: block;
        position: relative;
        z-index: 50 ;
        width: 100%;
        margin-bottom: 20px;

      }
    }
    &-info{
      display: none;
    }
    &-country{
      &-cards{
        overflow: scroll;
        &-slide{
          width: fit-content;
          display: flex;
          &-img{
            border-radius: $borderRadius;
            overflow: hidden;
            margin-right: 10px;
            background: white;
            height: fit-content;
            padding-bottom: 10px;
            h2{
              font-size: 10px;
              margin-left: 10px;
              margin-top: 5px;
            }
            p{
              font-size: 10px;
              margin: 0;
              margin-left: 10px;
            }
            img{
              height: 50px;
              width: 110px;
              object-fit: cover;
            }
          }
        }
      }
      &-card{
        width: 120px;
        position: relative;
        margin:  10px auto;
        @include card();
        height: 30px;
        img{
          width: 100%;
          filter: grayscale(0.2);
          height: 100%;
          position: relative;
          z-index: 1;
          object-fit: cover;
        }
        h2{
          position: absolute;
          display: none;
          font-size: 11px;
          bottom: 6px;
          left: 10px;
          color: black;
          z-index: 23;
          white-space: nowrap;
          background: white;
          padding: 3px 15px;
          border-radius: 8px;
        }
      }
    }
  }
}