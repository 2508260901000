@import "../../variables";

// Footer.scss
.footer {
  padding: 60px 20px 45px;
  background: $logoLight;
  display: block;
  color: $textColorLight;
  position: relative;

  &-btm{
    text-align: center;
    border-top: 1px solid #00000026;
    font-family: 'Lovelo',cursive;
    color: #00000085;
    padding-top: 45px;
    margin-top: 45px;
    border-top: 1px solid #00000026;
    font-size: 14px;
  }

  .footer-container{
    margin: auto;
    max-width: 1320px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
  }
  &-logo{
    img{
      max-width: 26px;
    }
    display: flex;
    align-items: center;
    h3{
      margin: 0;
      font-size: 45px;
      display: block;
    }
  }
  .footer-section {

    padding-left: 12px;
    .logo {
      max-width: 100%;
      width: 100px;
    }

    h3 {
      margin-top: 0;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        line-height: 1.5;
        margin-bottom: 5px;
        a{
          text-decoration: none;
          color: $textColorLight;
        }
        a:hover{
          color: $textColor;
          font-weight: bold;
        }
      }
    }
  }

  .social-media {
    display: flex;
    justify-content: end;
    svg{
      fill:$textColorLight ;
    }

    a {
      color: $textColorLight ;
      font-size: 35px;
      margin-right: 35px;
    }
    a:hover, svg:hover, path:hover{
      color: black;
      fill: black;
    }
  }
  p{
    font-weight: 600;
    font-size: 17px;
    margin-top: 7px;
  }
}


@media (max-width: 992px) {

  .footer{
    margin-top: 110px;
    .footer-container{
      grid-template-columns: 1fr;
    }
    .footer-section{
      margin-bottom: 25px;
    }
    .social-media {
      justify-content: start;
    }
  }

}

@media (max-width: 1400px) {
  .footer{
    margin-top: 110px;
  }
}

