
/**
$main: #cad2c5ff;
$second: #84a98cff;
$third: #52796fff;
$fourth: #354f52ff;
$last: #2f3e46ff;
$textColor:#172024;

$gradient-top: linear-gradient(0deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-right: linear-gradient(90deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-bottom: linear-gradient(180deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-left: linear-gradient(270deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-top-right: linear-gradient(45deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-bottom-right: linear-gradient(135deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-top-left: linear-gradient(225deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-bottom-left: linear-gradient(315deg, #cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);
$gradient-radial: radial-gradient(#cad2c5ff, #84a98cff, #52796fff, #354f52ff, #2f3e46ff);

**/


$logo: #ffb217;
$logoLight: #ede0c7;
$logoExtraLight: #e3cfa869;
$textBackground: #acb7cbff;

$main: #acb7cbff;
$second: #6379a2ff;
$third: #384b6eff;
$fourth: #253148ff;
$last: #222d41ff;
$textColor:#172024;
$textColorLight:#00000085;
$underColor: #f3f4f6;
$lightColor: #634a18;

$gradient-top: linear-gradient(0deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-right: linear-gradient(90deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-bottom: linear-gradient(180deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-left: linear-gradient(270deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-top-right: linear-gradient(45deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-bottom-right: linear-gradient(135deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-top-left: linear-gradient(225deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-bottom-left: linear-gradient(315deg, #acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);
$gradient-radial: radial-gradient(#acb7cbff, #6379a2ff, #384b6eff, #253148ff, #222d41ff);


$spaceSmall: 5px;
$spaceMain: 10px;
$spaceHalf: 20px;
$spaceMedium: 25px;
$spaceLarge: 45px;
$boxShadow: 1px 1px 1px #c4c4c4;
$borderRadius: 10px;

$fontSizeSmall: 13px;
$fontSizeNormal: 14px;
$fontSizeLarge: 16px;
$fontSizeXLarge: 18px;
$fontSizeBig: 22px
