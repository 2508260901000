@import "../../variables";

.eco-line-details{
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &-icon{
    margin-right: 15px;
    font-size: 19px;
  }
  &-txt{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55%;
    font-size: 14px;
  }
  &-value{
    margin-left: auto;
    font-family: 'Lovelo', cursive;
    font-size: 11px;
    background: #f3f4f6;
    color: #545e71;
    padding: 5px 10px;
    border-radius: $borderRadius;
    span{
      font-family: 'Lovelo', cursive;
    }
  }
}

.economy {
  &-table {
    &-row {
      &-icon {
        margin-right: 5px;
        width: 25px;
      }

      &-value {
        font-family: 'Lovelo', cursive;
        color: #596171;
      }
    }
    &-header {
      font-family: 'Lovelo', cursive !important;
      background: $underColor!important;
    }
  }
}