@import "variables";

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Raleway;
  src: url('fonts/Raleway-ExtraBold.ttf');
  font-weight: 900;
}

@font-face {
  font-family: Lovelo;
  src: url('fonts/Lovelo-Black.ttf');
  font-weight: 300;
}


body {
  margin: 0;
  font-family: Raleway, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: $logoExtraLight;
  font-weight: 400;
}
 p, div{
   font-weight: 400;
   font-size: $fontSizeLarge;
 }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: Raleway, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
}

*:focus{
  outline: none!important;
}

h1{
  font-size: 3rem;
  margin: 0;
  font-weight: 500;
  color: $fontSizeBig;
}

h2{
  font-size: $fontSizeLarge;
  font-weight: 400;
  margin: 0;
  color: $textColor;
}

h1,h2,h3,h4, button{
  font-family: Lovelo,cursive;
}
p{
  font-size: $fontSizeSmall;
}

button{
  cursor: pointer;
}