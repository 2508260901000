@import "../../variables";
@import "../../mixin";



.economy{
  min-height: 90vh;
  &-container{
    max-width: 1320px;
    position: relative;
    padding: 40px 20px;
    margin: auto;
  }
  h2{
  }
  &-country{
    &-container{
      display: flex;
      justify-content: space-between;
      height: 85px;
    }
    div{
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        font-size: 16px;
        margin: 0;
      }
    }
    h3{
      margin: 0;
    }
    display: flex;
    img{
      width: 100px;
    }
    margin-bottom: 25px;
  }
  &-category{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    button{
      border-radius: $borderRadius;
      border: none;
      background: none;
    }
    button:disabled{
      cursor: inherit;
    }
  }
  &-tab{
  margin-bottom: 40px;
    &-type{
      display: flex;
      height: 33px;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: end;
      button{
        margin-left: 15px;
        border-radius: $borderRadius;
        border: none;
        padding: 8px 16px;
        background: white;
      }
      button:hover{
        background: $logoLight;
      }
      &-active{
        background: $logo!important;
      }
      &-active:hover{
        background: $logo;
      }
    }
    &-select, &-select:hover, &-select:active, &-select:focus{
      background: white;
      height: 33px;
      outline: none!important;
      font-size: 13px!important;
      border: none!important;
      span{
        font-size: 13px!important;
      }
      div, fieldset{
        outline: none!important;
        border: none!important;
      }
    }
  }
  &-graph{
    position: relative;
    &-soon{
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      margin-top: 150px;
      height: 300px;
      &-text{
        position: absolute;
        z-index: 3;
        background-color: white;
        overflow: hidden;
        border-radius: 10px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        height: fit-content;
        text-align: center;
        width: fit-content;
        padding: 15px 50px 25px;
        color: #000000;
        span{
          font-size: 10px;
          padding: 5px;
          border: 1px solid #ffb217;
          margin-left: 15px;
          border-radius: 5px;
          background: #ffb217;
          color: black;
        }

        h3 {
          font-size: 18px;
          display: flex;
          justify-content: center;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}