@import "../../variables";
@import "../../mixin";

.map {
  &-main {
    padding: 0;

    &-container {
      height: 100%;
      overflow: initial;
      @include map-scale();
      position: absolute;

      svg {
        width: 100%;
        height: 100%;
        overflow: initial;
      }
    }
  }
}

path:hover, path:focus, path:active {
  cursor: pointer;
  fill: $logo;
  stroke: $fourth;
}

.circle {
  opacity: 0;
  fill: $logo;
  fill-opacity: 1;
  stroke: $fourth;
  stroke-width: 0.5;
  stroke-miterlimit: 3.97446823;
  stroke-dasharray: none;
  stroke-opacity: 1;
}

.land {
  fill: $logoLight;
  fill-opacity: 1;
  stroke: #777777;
  stroke-opacity: 1;
  stroke-width: 0.5;
  stroke-miterlimit: 3.97446823;
  stroke-dasharray: none;
}

.coast {
  stroke-width: 0.3;
}

.country {
  border-radius: $borderRadius;
  overflow: hidden;
  display: flex;
  height: 100%;
  background: white;

  &-left {
    border-right: 1px solid #222d412b;
    flex: 2 1;

    &-top {
      display: grid;
      grid-template-columns: 1fr 3fr;

      &-flag {
        border-bottom-right-radius: 5px;
        overflow: hidden;
        width: 100%;

        img {
          object-fit: contain;
          width: 100%;
          border-bottom-right-radius: 15px;
        }
      }

      &-text {
        padding-left: 1.5rem;
        padding-top: 1rem;

        h1 {
          margin-bottom: 5px;
          font-size: $fontSizeBig;
        }

        h2 {
          display: flex;
          align-items: center;
          font-size: $fontSizeXLarge;
          font-family: Raleway, cursive;

          svg {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }
      }
    }

    &-bottom {
      padding: $spaceMedium $spaceMedium $spaceMain $spaceMedium;
      font-size: $fontSizeLarge;


      &-summary {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 25px;

        &-row {
          display: flex;
          align-items: center;
          font-weight: 600;
          justify-content: center;

          &-title {
            font-size: 18px;
            font-weight: 600;
          }

          &-icon {
            margin-right: $spaceSmall;
          }

          &-icon:hover, path:hover {
            color: black;
            fill: black;
          }
        }

        &-row:first-child {
          justify-content: start;
        }

        &-row:last-child {
          justify-content: end;
        }
      }

      &-description {
        margin-top: 35px;

        p {
          text-align: justify;
          font-size: $fontSizeLarge;
          line-height: 22px;
        }
      }

      &-soon {
        border-radius: $borderRadius;
        overflow: hidden;
        position: relative;
        margin-top: 50px;
        height: 400px;

        img {
          object-fit: cover;
          width: 100%;
          position: relative;
          z-index: 1;
        }

        &-filter {
          position: absolute;
          z-index: 2;
          background: rgba(0, 0, 0, 0.61);
          height: 100%;
          width: 100%;
          top: 0;
        }

        &-text {
          position: absolute;
          z-index: 3;
          background-color: white;
          overflow: hidden;
          border-radius: 10px;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: 0 auto;
          height: fit-content;
          width: fit-content;
          text-align: center;
          padding: 30px 28px;
          color: #000000;

          h3 {
            font-size: 18px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  &-right {
    flex: 3 1;

    &-tab {
      border-bottom: 1px solid #222d412b;
      display: flex;

      &-close {
        margin: auto;
        height: 50px;
        margin-right: 25px;
        right: 0;
        position: relative;
        background: white;
        border: none;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &-close:hover, path:hover {
        color: black;
        fill: black;
      }


      &-btn {
        text-align: center;
        color: $last;
        background: none;
        padding: $spaceHalf $spaceMedium;
        border: none;
        cursor: pointer;
        font-size: $fontSizeLarge;
        font-weight: 700;
        border-bottom: 5px solid white;

        &-inactive {
          cursor: default;

          span {
            font-size: 10px;
            padding: 5px;
            border: 1px solid #ffb217;
            margin-left: 5px;
            border-radius: 5px;
            background: #ffb217;
            color: black;

          }
        }
      }

      &-active {
        color: $lightColor;
        font-weight: 700;
        border-bottom: 5px solid $logo;
      }


      &-btn:last-child {
        border-right: none;
      }
    }

    &-section {
      background-color: $underColor;
      padding: $spaceMedium;
      height: 100%;
      overflow: scroll;
      padding-bottom: 100px;
      &-container {
      }

      &-header {
        h2 {
          font-weight: 600;
          margin-bottom: $spaceSmall;
          font-size: $fontSizeLarge;
        }

        p {
          font-size: $fontSizeLarge;
          margin: 0;
        }

        margin-bottom: $spaceMedium;
      }
    }

    &-eco {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px ;

      &-section {
        margin-top: 25px;
        h2 {
          font-size: $fontSizeLarge;
          margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            span{
              font-size: 12px;
              color: #545e71;
              display: block;
              padding-top: 3px;
              padding-right: 5px;
            }
        }

        h3 {
          font-size: $fontSizeNormal;
        }

        p {
          font-size: $fontSizeNormal;
        }


        &-card {
          @include card();
          margin-bottom: 20px;
          padding: 0 25px;

            position: relative;
          a{
            text-decoration: none;
            color: black;
          }
          &-pro{
            position: absolute;
            background: #fffffff0;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            cursor: pointer;
            padding:  20px;
            div{
              font-size: 11px;
              font-family: 'Lovelo', cursive;
              color: black;
            }
            button, a{
              padding: 5px 24px;
              display: block;
              width: fit-content;
              margin: 15px auto;
              border: none;
              font-family: 'Lovelo', cursive;
              color: black;
              text-decoration: none;
              font-size: 10px;
              border-radius: $borderRadius;
              background: $logo;
            }
          }
          &-row{
            display: flex;
            margin-top: 25px;
            margin-bottom: 25px;
            &-obj{
              display: flex;
              align-items: center;
              overflow: hidden;
              padding-right: 25px;
              margin-right: 30px;
              height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 130px;
              img {
                width: 30px;
                margin-right: 7px;
                height: 100%;
              }
            }
          }
        }

        &-grid {
          display: grid;
          grid-gap: 25px;
          grid-template-columns: 1fr 1fr;
        }
        &-grid-country {
          display: grid;
          grid-gap: 25px;
          grid-template-columns: 1fr 1fr;
          .country-right-eco-section-card{
            margin-bottom: 0;

          }
        }
      }
    }

    &-books {
      &-container {
        height: 100%;
      }

      padding-right: $spaceMedium;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $spaceMedium;
      padding-bottom: 2rem;
    }

    &-book {
      display: grid;
      grid-template-columns: 130px auto;
      @include card();

      h3 {
        margin-top: $spaceSmall;
        font-size: $fontSizeNormal;
        font-weight: 600;
        margin-bottom: $spaceSmall;
      }

      &-img {
        img {
          height: 100%;
          width: 100%;
        }
      }

      &-description {
        padding: $spaceMain;
        display: flex;
        flex-direction: column;
        font-size: $fontSizeNormal;
        width: fit-content;
        margin: 0;

        &-text {
          margin-top: 7px;
          line-height: 19px;
          font-size: $fontSizeNormal;
        }

        &-date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          p {
            font-size: $fontSizeLarge;
            margin: 0;
            display: block;
          }

          span {
            text-transform: capitalize;
            border: 0.1px solid;
            padding: 5px 5px;
            border-radius: 7px;
            font-size: 11px;
            font-weight: 700;
            font-family: Lovelo, cursive;
            color: #c2570d;
            display: block;
          }
        }

        &-footer {
          margin-top: auto;

          &-author {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: $spaceSmall;

            p {
              display: block;
              margin: 0;
              font-size: $fontSizeNormal
            }

            svg {
              width: 25px;
              height: 25px;
            }

            a:hover {
              svg {
                fill: black;
              }
            }

            path:hover {
              fill: black;
            }
          }

        }
      }
    }
  }
}


@media (max-width: 992px) {
  .map {
    &-main {
      width: 100%;
      display: flex;
      justify-content: center;
      &-container{
        transform: scale(0.4);
        left: -143px;
        height: 340px;
        display: block;
        position: relative;
      }
    }
  }
  .country {
    display: flex;
    flex-direction: column;

    &-left {
      &-bottom {
        display: none;
      }
    }

    &-right {
      height: 100%;
      &-section {
        overflow: scroll;
      }
      &-eco{
        grid-template-columns: 1fr;
        grid-gap: 0;
        &-section{
          &-grid-country{
            grid-template-columns: 1fr;
            padding-bottom: 210px;
          }
        }
      }

      &-books {
        grid-template-columns: 1fr;
        padding: 0;
      }
    }
  }
  .country-right-tab-btn-inactive{
    display: none;
  }
}

@media (max-width: 1440px) {
  .country {
    &-left {
      &-top {
        &-flag {
          img {
          }
        }

        &-text {
          h1 {
            font-size: $fontSizeXLarge;
          }

          h2 {
            font-size: $fontSizeLarge;

            svg {
            }
          }
        }
      }

      &-bottom {
        font-size: $fontSizeSmall;
        padding: 25px 20px 10px 18px;

        &-summary {

          &-row {
            &-title {
              font-size: $fontSizeSmall;

            }
          }
        }

        &-description {
          p {
            font-size: $fontSizeSmall;
            line-height: 18px;
          }
        }
      }
    }

    &-right {
      flex: 4 1;

      &-tab {
        &-btn {
          font-size: $fontSizeNormal;
        }

      }
      &-eco{
        &-section{
          &-grid-country{
            padding-bottom: 210px;
          }
        }
      }
      &-section {
        overflow: scroll;
        &-container {
          padding-bottom: 150px;
        }
        &-header {
          h2 {
            font-size: $fontSizeNormal;
          }

          p {
            font-size: $fontSizeNormal;
          }
        }
      }

      &-books {
        &-container {
        }

      }

      &-book {
        grid-template-columns: 130px auto;

        h3 {
          font-size: $fontSizeSmall;
        }

        &-description {
          font-size: $fontSizeSmall;

          &-text {
            font-size: $fontSizeSmall;
            line-height: 18px;
          }

          &-date {
            p {
              font-size: $fontSizeSmall;
            }
          }

          &-footer {
            &-author {
              p {
                font-size: $fontSizeSmall
              }
            }
          }
        }
      }
    }
  }
}


